/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- VARIABLES (INITIALIZATION) ----------------- */
/* -------------------------------------------------- */
/* -------------------------------------------------- */



/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- IMPORTS ------------------------------------ */
/* -------------------------------------------------- */
/* -------------------------------------------------- */

import.meta.glob([
	'../images/**',
	'../fonts/**',
]);

import './bootstrap';
import * as img_func from './image-functions';
// import ImageProcessor from './skin-detect';

import $ from "jquery";
import { gsap } from "gsap";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { SplitText } from 'gsap/SplitText';
import Splide from '@splidejs/splide';
import { _ } from 'lodash';
import * as faceapi from "face-api.js";
import Alpine from 'alpinejs';
window.Alpine = Alpine;

/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- VARIABLES ---------------------------------- */
/* -------------------------------------------------- */
/* -------------------------------------------------- */

gsap.registerPlugin( ScrollSmoother, ScrollTrigger, ScrambleTextPlugin, ScrollToPlugin, SplitText );

/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- GENERAL ------------------------------------ */
/* -------------------------------------------------- */
/* -------------------------------------------------- */

( function( selector )
{
	'use strict'

    var scope = $( selector );
    if( !scope.length ) return;

    var colors = [ [ '#FAD6C0', '#E8C3A8', '#DCB58C', '#DCA475' ], [ '#DF9D7B', '#C6926A', '#9F664B', '#834432' ], [ '#6D3C2E', '#723A29', '#5C2E1F', '#773C2A' ] ];

    for (var i = 0; i < scope.length; i++)
    {
	    scope.eq( i ).append( "<div class='logo-palette'></div>" );

	    var grid = new Array();
	    for (var x = 0; x < 3; x++)
	    {
	    	grid.push( new Array() );
		    for (var y = 0; y < 4; y++)
		    {
		    	grid[ x ].push( new Array() );
		    	grid[ x ][ y ] = scope.eq( i ).find( '.logo-palette' ).append( "<div class='palette'></div>" ).children( '.palette' ).eq( ( x * 4 ) + y );
		    	grid[ x ][ y ].css( "background-color", colors[ x ][ y ] );
		    	gsap.to( grid[ x ][ y ], { opacity: "random( 0.1, 0.5, 0.05 )", duration: "random( 1, 2, 0.25 )", repeat: -1, yoyo: true } );
		    }
	    }
    }
    
})( '.logo-palette-holder', '.logo-palette-holder.sm' );



( function( selector )
{
	'use strict'

    var scope = $( selector );
    if( !scope.length ) return;

	$( document ).ready( function()
	{
	    scope.removeClass( 'loading' );
	});

    
})( 'body[data-section="home"], body[data-section="webapp"]' );

/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- HOMEPAGE ----------------------------------- */
/* -------------------------------------------------- */
/* -------------------------------------------------- */

( function( selector )
{
	'use strict'

    var scope = $( selector );
    if( !scope.length ) return;

	window.addEventListener( 'resize', _.debounce(() =>
	{
	 	ScrollTrigger.refresh();
	}, 100 ));

	document.addEventListener( 'alpine:init', () => {
	    Alpine.data( 'modelOpen', () => ({
	        open: false,
	        image( url ) { this.$refs.image.src = url; },
	        toggle() { this.open = !this.open; },
			init() {
				this.$watch('open', () => {
					gsap.to( "#smooth-wrapper", { filter: "blur(" + (this.open ? '10px': '0px') + ")", ease: "power2.in", duration: 0.3 });
				})
			}
	    })),
	    Alpine.data( 'menuOpen', () => ({
	        open: false,
	        toggle() { this.open = !this.open; }
	    }));
	});

	window.modelOpen_open = function ( e ) { var ref = document.getElementById( 'modelOpen' )._x_dataStack[ 0 ]; ref.open = true; ref.image( e.getElementsByTagName( 'img' )[ 0 ].getAttribute('src') );  }
	window.modelOpen_close = function ( e ) { var ref = document.getElementById( 'modelOpen' )._x_dataStack[ 0 ]; ref.open = false; }

	Alpine.start();

	$( document ).ready( function()
	{

		ScrollTrigger.normalizeScroll( true );

		var mm = gsap.matchMedia();

		// mm.add( "(min-width: 768px)", () => {

			// create the scrollSmoother before your scrollTriggers
			let smoother = ScrollSmoother.create({
				smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
				effects: true, // looks for data-speed and data-lag attributes on elements
				// smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
	  			normalizeScroll: true
			});
		// });

		/* SETUP THE NAVIGATION MENU */
		( function( selector )
		{
			'use strict'

		    var scope = $( selector );
		    if( !scope.length ) return;

		    var $ele = $( scope ).find( '.nav-link' );
		    for (var i = 0; i < $ele.length; i++)
		    {
			    $ele.eq( i ).off( 'click' ).on( 'click', function( e ) {
			    	var $tgt = $( $( e.target ).data( 'nav' ) ).eq( 0 );
					gsap.to( window, { 
						duration: 1,
						scrollTo: $tgt.offset().top + 1,
						ease: "power2.out"
					} );
			    } );
		    }

	    	/* NAV TIMELINE */

		    var sections = scope.parents( '.menu-holder-mobile' ).find( '.nav-link' );
		    for( var i = 0; i < sections.length; i++ )
		    {
				var t_nav = gsap.timeline({
				    scrollTrigger: {
				        trigger: $( sections.eq( i ).data( 'nav' ) ),
				        start: 'top center', // when the top of the trigger hits the top of the viewport
				        end: 'bottom center', // end after scrolling 500px beyond the start
				        scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
				        toggleClass: { targets: $( '.menu-holder .nav-link[ data-nav="' + sections.eq( i ).data( 'nav' ) + '" ]' ), className: "active" },
				        // markers: true,
				    }
				});
		    }
		    
		})( 'nav .menu-holder' );

    	/* -------------------------------------------------- */
    	/* -------------------------------------------------- */
    	/* SECTION 1 TIMELINE ------------------------------- */
    	/* -------------------------------------------------- */
    	/* -------------------------------------------------- */

		/* SETUP THE CAROUSEL */
		var splides_ele = $( '.carousel-hero .splide__list .splide__slide' );
    	for( var i = splides_ele.length - 1; i >= 0; i-- )
    	{
    		var info = $( splides_ele[ i ] ).children( '.img-holder' ).eq( 0 ).append( "<div class='img-info'><div class='title'><div class='header'>#268ToneProud</div><div class='sub-header'>CAPTURE YOUR UNIQUE TONE</div></div><div class='footer'><div class='name'></div>&nbsp;<div class='value'></div></div></div>" ).children( '.img-info' );
    			info.find( ".footer .name" ).html( info.parent().data( 'name' ) );
    			info.find( ".footer .value" ).html( info.parent().data( 'value' ) );
    			
    			var arr = info.siblings( "img" ).eq( 0 ).attr( "srcset" ).split( "," );
    			var tgt = new URL( arr[ 0 ].split( " " )[ 0 ] );
    			info.parent().css( "background-image", "url(" + tgt.href + ")" );
    			info.parent().css( "background-position", "center" );
    			info.parent().css( "background-size", "cover" );
    	}

		var splide = new Splide( '.carousel-hero', {
			type: 'loop',
			speed: 500,
			arrows: false,
			autoplay: true,
			interval: 6000,
			waitForTransition: true,
			pauseOnHover: false,
			pauseOnFocus: false,
			start: Math.floor( Math.random() * $( '.carousel-hero .splide__slide' ).length ),
		} );

    	splide.on( 'mounted', function() {} );
    	splide.on( 'moved', function( newIndex, prevIndex, destIndex ) {
    		var $ref = $( splide.Components.Elements.slides[ newIndex ] ).find( '.img-info .title' );
    		gsap.to( $ref.find( '.header' ), {
				duration: 1, 
				scrambleText: {
					text: "#268ToneProud", 
					chars: "upperCase", 
					revealDelay: 0.4, 
					speed: 0.3, 
				}
			});
    		gsap.to( $ref.find( '.sub-header' ), {
				duration: 1.5, 
				// delay: 0.5,
				scrambleText: {
					text: "CAPTURE YOUR UNIQUE TONE", 
					chars: "upperCase", 
					revealDelay: 0.4, 
					speed: 0.3, 
				}
			});
    	} );
    	splide.mount();

    	/* -------------------------------------------------- */
    	/* -------------------------------------------------- */
    	/* SECTION 2 TIMELINE ------------------------------- */
    	/* -------------------------------------------------- */
    	/* -------------------------------------------------- */

		var $ref = $( '.section-2 .header .content-right' );
			$ref.data( 'txt' ) == undefined ? $ref.data( 'txt', $ref.text() ): '';
		var st_2 = new SplitText( $ref, { type: "chars,words" } );

		var arr = $( '.section-2 .video-holder .img-holder img' ).eq( 0 ).attr( "srcset" ).split( "," );
		var tgt = new URL( arr[ 0 ].split( " " )[ 0 ] );
		$( '.section-2 .video-holder .img-holder' ).css( "background-image", "url(" + tgt.href + ")" );
		$( '.section-2 .video-holder .img-holder' ).css( "background-position", "center" );
		$( '.section-2 .video-holder .img-holder' ).css( "background-size", "cover" );

		let mm_2 = gsap.matchMedia();
		let mm_2_breakPoint = 768;

		mm_2.add({
			// set up any number of arbitrarily-named conditions. The function below will be called when ANY of them match.
			isDesktop: `(min-width: ${mm_2_breakPoint}px) and (prefers-reduced-motion: no-preference)`,
			isMobile: `(max-width: ${mm_2_breakPoint - 1}px) and (prefers-reduced-motion: no-preference)`
		}, (context) => {
			// context.conditions has a boolean property for each condition defined above indicating if it's matched or not.
			let { isDesktop, isMobile } = context.conditions;

			let t2_1 = gsap.timeline({
			    scrollTrigger: {
			        trigger: '.section-2 .header .content-left',
			        start: 'top 75%',
			        end: '+=' + ( isDesktop ? '25': '50' ) + '%',
			        scrub: 1,
			        // markers: true,
			    }
			});
			
			let t2_2 = gsap.timeline({
			    scrollTrigger: {
			        trigger: '.section-2 .header .content-right',
			        start: 'top 75%',
			        end: '+=' + ( isDesktop ? '25': '25' ) + '%',
			        scrub: 1,
			        // markers: true,
			    }
			});

			let t2_3 = gsap.timeline({
			    scrollTrigger: {
			        trigger: '.section-2 .video-holder',
			        start: ( isDesktop ? 'top': 'center-=25%' ) + ' 50%',
			        end: ( isDesktop ? 'center': 'center' ) + ' 50%',
			        // end: '+=' + ( isDesktop ? '25': '50' ) + '%',
			        scrub: 1,
			        // markers: true,
			    }
			});

			t2_1.addLabel( 't2_1-start' )
				.from( '.section-2 .header .content-left', { autoAlpha: 0, ease: "power2.out" }, 't2_1-start')
				.from( '.section-2 .header .content-left', { xPercent: -10, ease: "power1.out" }, 't2_1-start')
			  	.addLabel('t2_1-end');

			t2_2.addLabel( 't2_2-start' )
			  	.from( st_2.words, {
					// duration: 1,
					opacity: 0,
					transformOrigin: "0% 50% -50",
					translateX: "10px",
					ease: "power1.inOut",
					stagger: 0.1
			  	}, 't2_2-start' )
			  	.addLabel('t2_2-end');

			t2_3.addLabel( 't2_3-start' )
				.from( '.section-2 .video-holder .info .msg', { autoAlpha: 0, translateY: ( isDesktop ? '': '-' ) + '20px', ease: "power1.inOut", delay: ( isDesktop ? '0.0': '0.5' ) }, 't2_3-start' )
				.from( '.section-2 .video-holder .info .title', { autoAlpha: 0, translateY: ( isDesktop ? '': '-' ) + '20px', ease: "power1.inOut", delay: ( isDesktop ? '0.25': '0.25' ) }, 't2_3-start' )
				.from( '.section-2 .video-holder .info .cta', { autoAlpha: 0, translateY: ( isDesktop ? '': '-' ) + '20px', ease: "power1.inOut", delay: ( isDesktop ? '0.5': '0.0' ) }, 't2_3-start' )
			  	.addLabel('t2_3-end');

			return () => { 
				// optionally return a cleanup function that will be called when the media query no longer matches
			}
		});

		let t2_pin = gsap.timeline({
		    // yes, we can add it to an entire timeline!
		    scrollTrigger: {
		        trigger: '.section-2',
		        endTrigger: ".section-2 .video-holder",
		        start: 'top 0%',
		        end: 'top 0%',
		        // end: () => 'top+=' + ( $( '.section-2 .video-holder' ).height() ) + ' 0%',
		        scrub: 1,
		        pin: '.section-2-pin',
		        pinSpacing: false,
		        // markers: true,
		    }
		});


    	/* -------------------------------------------------- */
    	/* -------------------------------------------------- */
    	/* SECTION 3 TIMELINE ------------------------------- */
    	/* -------------------------------------------------- */
    	/* -------------------------------------------------- */

		let t3 = gsap.timeline({
		    // yes, we can add it to an entire timeline!
		    scrollTrigger: {
		        trigger: '.section-3',
		        start: 'top 75%', // when the top of the trigger hits the top of the viewport
		        end: '+=50%', // end after scrolling 500px beyond the start
		        scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
		        // markers: true,
		    }
		});

		// add animations and labels to the timeline
		t3.addLabel( 't3-start' )
		  .from( '.section-3 .header .msg', { autoAlpha: 0, ease: "power2.out" }, 't3-start')
		  .from( '.section-3 .header .msg', { translateY: '-20px', scale: 0.95, ease: "power1.out" }, 't3-start')
		  .addLabel('t3-end');

		var $ref = $( '.section-3 .content-left .content-left-container .info' );
			$ref.data( 'txt' ) == undefined ? $ref.data( 'txt', $ref.text() ): '';
		var st_3 = new SplitText( $ref, { type: "chars,words" } );

		let mm_3 = gsap.matchMedia();
		let mm_3_breakPoint = 768;

		mm_3.add({
			// set up any number of arbitrarily-named conditions. The function below will be called when ANY of them match.
			isDesktop: `(min-width: ${mm_3_breakPoint}px) and (prefers-reduced-motion: no-preference)`,
			isMobile: `(max-width: ${mm_3_breakPoint - 1}px) and (prefers-reduced-motion: no-preference)`
		}, (context) => {
			// context.conditions has a boolean property for each condition defined above indicating if it's matched or not.
			let { isDesktop, isMobile } = context.conditions;

			let t3_1 = gsap.timeline({
			    scrollTrigger: {
			        trigger: '.section-3 .content-right',
			        start: 'top 75%',
			        end: '+=' + ( isDesktop ? '25': '25' ) + '%',
			        scrub: 1,
			        // markers: true,
			    }
			});

			let t3_2 = gsap.timeline({
			    scrollTrigger: {
			        trigger: '.section-3 .content-left',
			        start: 'top 75%',
			        end: '+=' + ( isDesktop ? '25': '25' ) + '%',
			        scrub: 1,
			        // markers: true,
			    }
			});

			let t3_3 = gsap.timeline({
			    scrollTrigger: {
			        trigger: '.section-3 .card .content-left',
			        start: 'top 75%',
			        end: '+=' + ( isDesktop ? '50': '50' ) + '%',
			        scrub: 1,
			        // markers: true,
			    }
			});
			
			let t3_4 = gsap.timeline({
			    scrollTrigger: {
			        trigger: '.section-3 .card .content-right',
			        start: 'top 75%',
			        end: '+=' + ( isDesktop ? '50': '50' ) + '%',
			        scrub: 1,
			        // markers: true,
			    }
			});

			t3_1.addLabel( 't3_1-start' )
			  .from( '.section-3 .content-right', { autoAlpha: 0, ease: "power2.out" }, 't3_1-start')
			  .from( '.section-3 .content-right', { xPercent: 10, ease: "power1.out" }, 't3_1-start')
			  .addLabel('t3_1-end');

			t3_2.addLabel( 't3_2-start' )
			  	.from( st_3.words, {
					// duration: 1,
					opacity: 0,
					transformOrigin: "0% 50%",
					translateX: "10px",
					ease: "power1.inOut",
					stagger: 0.1
			  	}, 't3_2-start')
			  	.addLabel('t3_2-end');

			t3_3.addLabel( 't3_3-start' )
				.from( '.section-3 .card .content-left .img-holder', { translateY: "20px", ease: "power2.out" }, 't3_3-start')
				.from( '.section-3 .card .content-left .info', { autoAlpha: 0, translateY: '20px', ease: "power1.out" })
			  	.addLabel('t3_3-end');

			t3_4.addLabel( 't3_4-start' )
				.from( '.section-3 .card .content-right .img-holder', { translateY: "20px", ease: "power2.out" }, 't3_4-start')
				.from( '.section-3 .card .content-right .info', { autoAlpha: 0, translateY: '20px', ease: "power1.out" })
			  	.addLabel('t3_4-end');

			return () => { 
				// optionally return a cleanup function that will be called when the media query no longer matches
			}
		});

    	/* -------------------------------------------------- */
    	/* -------------------------------------------------- */
    	/* SECTION 4 TIMELINE ------------------------------- */
    	/* -------------------------------------------------- */
    	/* -------------------------------------------------- */

		/* SETUP THE CAROUSEL */
		var splide_details = new Splide( '.carousel-details', {
			// type: 'loop',
			speed: 500,
			arrows: false,
			waitForTransition: true,
			perPage: 3,
			gap: '4rem',
			drag: false,
			breakpoints: {
				767: {
					perPage: 1,
					gap: '1rem',
					pagination: false,
					fixedWidth: '95%',
					padding: { left: '1.5rem', right: '1.5rem' },
					drag: true,
				},
			},
		} );

    	splide_details.on( 'mounted', function() {} );
    	splide_details.on( 'moved', function( newIndex, prevIndex, destIndex ) { } );
    	splide_details.mount();

		let mm_4 = gsap.matchMedia();
		let mm_4_breakPoint = 768;

		mm_4.add({
			// set up any number of arbitrarily-named conditions. The function below will be called when ANY of them match.
			isDesktop: `(min-width: ${mm_4_breakPoint}px) and (prefers-reduced-motion: no-preference)`,
			isMobile: `(max-width: ${mm_4_breakPoint - 1}px) and (prefers-reduced-motion: no-preference)`
		}, (context) => {
			// context.conditions has a boolean property for each condition defined above indicating if it's matched or not.
			let { isDesktop, isMobile } = context.conditions;

			let t4_1 = gsap.timeline({
			    scrollTrigger: {
			        trigger: '.section-4 .carousel-details',
			        start: 'top 75%',
			        end: '+=' + ( isDesktop ? '25': '25' ) + '%',
			        scrub: 1,
			        // markers: true,
			    }
			});

			if( isDesktop )
			{
				t4_1.addLabel( 't4_1-start' )
				  .from( '.section-4 .carousel-details .content', { autoAlpha: 0, ease: "power2.out", stagger: 0.1 }, 't4_1-start')
				  .from( '.section-4 .carousel-details .content', { yPercent: 10, ease: "power1.out",stagger: 0.1 }, 't4_1-start')
				  .addLabel('t4_1-end');
			}
			else
			{
				t4_1.addLabel( 't4_1-start' )
				  .from( '.section-4 .carousel-details', { autoAlpha: 0, ease: "power2.out" }, 't4_1-start')
				  .from( '.section-4 .carousel-details', { yPercent: 10, ease: "power1.out" }, 't4_1-start')
				  .addLabel('t4_1-end');
			}


			return () => { 
				// optionally return a cleanup function that will be called when the media query no longer matches
			}
		});

    	/* -------------------------------------------------- */
    	/* -------------------------------------------------- */
    	/* SECTION 5 TIMELINE ------------------------------- */
    	/* -------------------------------------------------- */
    	/* -------------------------------------------------- */

		var $ref = $( '.section-5 .header .content-left .txt' );
			$ref.data( 'txt' ) == undefined ? $ref.data( 'txt', $ref.text() ): '';
		var st_5 = new SplitText( $ref, { type: "chars,words" } );

		let mm_5 = gsap.matchMedia();
		let mm_5_breakPoint = 768;

		mm_5.add({
			// set up any number of arbitrarily-named conditions. The function below will be called when ANY of them match.
			isDesktop: `(min-width: ${mm_5_breakPoint}px) and (prefers-reduced-motion: no-preference)`,
			isMobile: `(max-width: ${mm_5_breakPoint - 1}px) and (prefers-reduced-motion: no-preference)`
		}, (context) => {
			// context.conditions has a boolean property for each condition defined above indicating if it's matched or not.
			let { isDesktop, isMobile } = context.conditions;

			let t5_1 = gsap.timeline({
			    scrollTrigger: {
			        trigger: '.section-5 .header .content-left',
			        start: 'top 75%',
			        end: '+=' + ( isDesktop ? '25': '25' ) + '%',
			        scrub: 1,
			        // markers: true,
			    }
			});

			let t5_2 = gsap.timeline({
			    // yes, we can add it to an entire timeline!
			    scrollTrigger: {
			        trigger: '.section-5 .gallery-holder',
			        start: 'top 75%',
			        end: isDesktop ? '+=25%': 'bottom 75%',
			        scrub: 1,
			        // markers: true,
			    }
			});

			t5_1.addLabel( 't5_1-start' )
			  	.from( st_5.words, {
					// duration: 1,
					opacity: 0,
					transformOrigin: "0% 50%",
					translateX: "10px",
					ease: "power1.inOut",
					stagger: 0.1
			  	}, 't5_1-start')
			  	.addLabel('t5_1-end');

			t5_2.addLabel( 't5_2-start' )
			  .from( '.section-5 .gallery' + ( isMobile ? ' .content': '' ), { autoAlpha: 0, translateY: isMobile ? 0: 20, scale: isMobile ? 0.9: 1, transformOrigin: "50% 50%", stagger: ( isMobile ? 0.1: 0 ), ease: "power2.out" })
			  .addLabel('t5_2-end');

			return () => { 
				// optionally return a cleanup function that will be called when the media query no longer matches
			}
		});
	});

    
})( 'body[data-section="home"]' );

/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- WEBAPP ------------------------------------- */
/* -------------------------------------------------- */
/* -------------------------------------------------- */

( function( selector )
{
	'use strict'

    var scope = $( selector );
    if( !scope.length ) return;

	document.addEventListener( 'alpine:init', () => {
	    Alpine.data( 'videoError', () => ({
	        error: false,
			init() {
				this.$watch( 'error', () => {
					$( '.face-api-info' )[ this.error ? 'addClass': 'removeClass' ]( 'error' );
				})
			}
	    }));
	});

	window.videoError_true = function ( txt ) { var ref = document.getElementById( 'videoError' )._x_dataStack[ 0 ]; ref.error = true; $( '.face-api-info .text' ).text( txt ); }
	window.videoError_false = function ( txt ) { var ref = document.getElementById( 'videoError' )._x_dataStack[ 0 ]; ref.error = false; $( '.face-api-info .text' ).text( txt ); }

	Alpine.start();

    // async function requestExternalImage(imageUrl) {
    // 	const res = await fetch('fetch_external_image', {
    // 		method: 'post',
    // 		headers: {
    // 			'content-type': 'application/json'
    // 		},
    // 		body: JSON.stringify({ imageUrl })
    // 	})
    // 	if (!(res.status < 400)) {
    // 		console.error(res.status + ' : ' + await res.text())
    // 		throw new Error('failed to fetch image from url: ' + imageUrl)
    // 	}

    // 	let blob
    // 	try {
    // 		blob = await res.blob()
    // 		return await faceapi.bufferToImage(blob)
    // 	} catch (e) {
    // 		console.error('received blob:', blob)
    // 		console.error('error:', e)
    // 		throw new Error('failed to load image from url: ' + imageUrl)
    // 	}
    // }

    let recordedResult = undefined;
    let cam_facing = 'user'; // 'user' or 'environment'
    let cam_facing_prev = cam_facing;
    let cam_state = 'loading';

    const result_display = true;
	const tone_card = [];
		  tone_card.push( "#fffeff" );
		  tone_card.push( "#7d7d7d" );
		  tone_card.push( "#000000" );
		  tone_card.push( "#fffeff" );
		  tone_card.push( "#7d7d7d" );
		  tone_card.push( "#000000" );
		  tone_card.push( "#fffeff" );
		  tone_card.push( "#7d7d7d" );
		  tone_card.push( "#000000" );
		  tone_card.push( "#fffeff" );
		  tone_card.push( "#7d7d7d" );
		  tone_card.push( "#000000" );
		  tone_card.push( "#fffeff" );
		  tone_card.push( "#7d7d7d" );
		  tone_card.push( "#fffeff" );
		  tone_card.push( "#7d7d7d" );
		  tone_card.push( "#7f5744" );
		  tone_card.push( "#67493a" );
		  tone_card.push( "#6e4d3d" );
		  tone_card.push( "#c18f7f" );
		  tone_card.push( "#56423c" );
		  tone_card.push( "#a2706c" );
		  tone_card.push( "#b7957d" );
		  tone_card.push( "#664b41" );
		  tone_card.push( "#8a624d" );
		  tone_card.push( "#584844" );
		  tone_card.push( "#a78067" );
		  tone_card.push( "#a37865" );
		  tone_card.push( "#b29381" );
		  tone_card.push( "#7d7d7d" );
		  tone_card.push( "#000000" );
		  tone_card.push( "#a88066" );
		  tone_card.push( "#56433e" );
		  tone_card.push( "#886652" );
		  tone_card.push( "#a48062" );
		  tone_card.push( "#694e40" );
		  tone_card.push( "#bba189" );
		  tone_card.push( "#bb9f86" );
		  tone_card.push( "#745445" );
		  tone_card.push( "#7b5647" );
		  tone_card.push( "#553f39" );
		  tone_card.push( "#94725c" );
		  tone_card.push( "#a07f65" );
		  tone_card.push( "#987367" );
		  tone_card.push( "#000000" );
		  tone_card.push( "#fffeff" );
		  tone_card.push( "#735145" );
		  tone_card.push( "#9d7964" );
		  tone_card.push( "#8b6149" );
		  tone_card.push( "#84624d" );
		  tone_card.push( "#af8b7b" );
		  tone_card.push( "#b39483" );
		  tone_card.push( "#ba9e83" );
		  tone_card.push( "#52403b" );
		  tone_card.push( "#a17963" );
		  tone_card.push( "#8d5e4f" );
		  tone_card.push( "#9f7d6a" );
		  tone_card.push( "#b68f79" );
		  tone_card.push( "#6e5645" );
		  tone_card.push( "#fffeff" );
		  tone_card.push( "#7d7c7d" );
		  tone_card.push( "#56453f" );
		  tone_card.push( "#6c4b3d" );
		  tone_card.push( "#4a362f" );
		  tone_card.push( "#bd9582" );
		  tone_card.push( "#bc8c83" );
		  tone_card.push( "#916b52" );
		  tone_card.push( "#ae8671" );
		  tone_card.push( "#7f5b47" );
		  tone_card.push( "#996c5a" );
		  tone_card.push( "#413632" );
		  tone_card.push( "#ab9178" );
		  tone_card.push( "#b2937d" );
		  tone_card.push( "#8f6959" );
		  tone_card.push( "#7d7c7d" );
		  tone_card.push( "#000000" );
		  tone_card.push( "#a0705a" );
		  tone_card.push( "#51413e" );
		  tone_card.push( "#6c4b3d" );
		  tone_card.push( "#6a5446" );
		  tone_card.push( "#9b7962" );
		  tone_card.push( "#60473f" );
		  tone_card.push( "#b98e7c" );
		  tone_card.push( "#58443d" );
		  tone_card.push( "#8a664d" );
		  tone_card.push( "#2d1e17" );
		  tone_card.push( "#a47f6d" );
		  tone_card.push( "#9b765d" );
		  tone_card.push( "#af867b" );
		  tone_card.push( "#000000" );
		  tone_card.push( "#fffeff" );
		  tone_card.push( "#8f6855" );
		  tone_card.push( "#856048" );
		  tone_card.push( "#775449" );
		  tone_card.push( "#bc9e8b" );
		  tone_card.push( "#6d4e43" );
		  tone_card.push( "#a8886c" );
		  tone_card.push( "#b3836e" );
		  tone_card.push( "#61473d" );
		  tone_card.push( "#433937" );
		  tone_card.push( "#b69179" );
		  tone_card.push( "#a58a72" );
		  tone_card.push( "#b38f7e" );
		  tone_card.push( "#82665a" );
		  tone_card.push( "#fffeff" );
		  tone_card.push( "#7d7c7d" );
		  tone_card.push( "#99755f" );
		  tone_card.push( "#976a56" );
		  tone_card.push( "#4f3c38" );
		  tone_card.push( "#99765b" );
		  tone_card.push( "#191819" );
		  tone_card.push( "#4b4a4b" );
		  tone_card.push( "#7d7c7d" );
		  tone_card.push( "#afaeaf" );
		  tone_card.push( "#e1e0e1" );
		  tone_card.push( "#af8068" );
		  tone_card.push( "#987260" );
		  tone_card.push( "#a98a78" );
		  tone_card.push( "#775746" );
		  tone_card.push( "#7d7c7d" );
		  tone_card.push( "#000000" );
		  tone_card.push( "#7c5346" );
		  tone_card.push( "#56433c" );
		  tone_card.push( "#a9846b" );
		  tone_card.push( "#927160" );
		  tone_card.push( "#323132" );
		  tone_card.push( "#646364" );
		  tone_card.push( "#969596" );
		  tone_card.push( "#c8c7c8" );
		  tone_card.push( "#fffeff" );
		  tone_card.push( "#b29176" );
		  tone_card.push( "#a8856d" );
		  tone_card.push( "#876750" );
		  tone_card.push( "#6e5244" );
		  tone_card.push( "#000000" );
		  tone_card.push( "#fffeff" );
		  tone_card.push( "#6c4f42" );
		  tone_card.push( "#a8876d" );
		  tone_card.push( "#b38674" );
		  tone_card.push( "#b49d87" );
		  tone_card.push( "#ae8376" );
		  tone_card.push( "#9b725a" );
		  tone_card.push( "#a97e6c" );
		  tone_card.push( "#6f4f43" );
		  tone_card.push( "#42322e" );
		  tone_card.push( "#b28776" );
		  tone_card.push( "#a0836d" );
		  tone_card.push( "#af8d71" );
		  tone_card.push( "#9c6e62" );
		  tone_card.push( "#fffeff" );
		  tone_card.push( "#7d7c7d" );
		  tone_card.push( "#7b5648" );
		  tone_card.push( "#875d4e" );
		  tone_card.push( "#a57568" );
		  tone_card.push( "#ae9277" );
		  tone_card.push( "#795745" );
		  tone_card.push( "#ae8570" );
		  tone_card.push( "#b58777" );
		  tone_card.push( "#956f56" );
		  tone_card.push( "#87594b" );
		  tone_card.push( "#90674a" );
		  tone_card.push( "#a78574" );
		  tone_card.push( "#9f8065" );
		  tone_card.push( "#715e51" );
		  tone_card.push( "#7d7c7d" );
		  tone_card.push( "#000000" );
		  tone_card.push( "#97755c" );
		  tone_card.push( "#8e624d" );
		  tone_card.push( "#b29075" );
		  tone_card.push( "#8a6e57" );
		  tone_card.push( "#956b59" );
		  tone_card.push( "#a58370" );
		  tone_card.push( "#c38c7e" );
		  tone_card.push( "#7f5848" );
		  tone_card.push( "#895d46" );
		  tone_card.push( "#a87959" );
		  tone_card.push( "#a68975" );
		  tone_card.push( "#876451" );
		  tone_card.push( "#bb9d8a" );
		  tone_card.push( "#000000" );
		  tone_card.push( "#fffeff" );
		  tone_card.push( "#4c3f3b" );
		  tone_card.push( "#835b4d" );
		  tone_card.push( "#a47666" );
		  tone_card.push( "#a47e6e" );
		  tone_card.push( "#ad8e79" );
		  tone_card.push( "#b29072" );
		  tone_card.push( "#60473e" );
		  tone_card.push( "#8d654d" );
		  tone_card.push( "#956454" );
		  tone_card.push( "#816148" );
		  tone_card.push( "#b39c85" );
		  tone_card.push( "#947961" );
		  tone_card.push( "#5c4336" );
		  tone_card.push( "#fffeff" );
		  tone_card.push( "#7d7c7d" );
		  tone_card.push( "#a37c62" );
		  tone_card.push( "#8e6651" );
		  tone_card.push( "#b48d77" );
		  tone_card.push( "#8c665a" );
		  tone_card.push( "#a47965" );
		  tone_card.push( "#aa856a" );
		  tone_card.push( "#674b40" );
		  tone_card.push( "#765543" );
		  tone_card.push( "#6d4c43" );
		  tone_card.push( "#b18d6f" );
		  tone_card.push( "#b18671" );
		  tone_card.push( "#a87c6c" );
		  tone_card.push( "#9d716a" );
		  tone_card.push( "#7d7c7d" );
		  tone_card.push( "#000000" );
		  tone_card.push( "#58433d" );
		  tone_card.push( "#a1735f" );
		  tone_card.push( "#bc8378" );
		  tone_card.push( "#89624d" );
		  tone_card.push( "#bb9384" );
		  tone_card.push( "#b18b74" );
		  tone_card.push( "#6c4d40" );
		  tone_card.push( "#906a55" );
		  tone_card.push( "#5a534d" );
		  tone_card.push( "#a78263" );
		  tone_card.push( "#987a66" );
		  tone_card.push( "#97775d" );
		  tone_card.push( "#6c4a43" );
		  tone_card.push( "#000000" );
		  tone_card.push( "#fffeff" );
		  tone_card.push( "#7d5a48" );
		  tone_card.push( "#a37c62" );
		  tone_card.push( "#a3856e" );
		  tone_card.push( "#9f775c" );
		  tone_card.push( "#a77e67" );
		  tone_card.push( "#bb947f" );
		  tone_card.push( "#5c453f" );
		  tone_card.push( "#a78268" );
		  tone_card.push( "#885d45" );
		  tone_card.push( "#a57467" );
		  tone_card.push( "#ae8574" );
		  tone_card.push( "#866d58" );
		  tone_card.push( "#34201c" );
		  tone_card.push( "#fffeff" );
		  tone_card.push( "#7d7c7d" );
		  tone_card.push( "#986b59" );
		  tone_card.push( "#815b48" );
		  tone_card.push( "#795647" );
		  tone_card.push( "#a4766d" );
		  tone_card.push( "#ad927d" );
		  tone_card.push( "#a87f67" );
		  tone_card.push( "#755242" );
		  tone_card.push( "#845f4a" );
		  tone_card.push( "#8c6649" );
		  tone_card.push( "#9e6b5b" );
		  tone_card.push( "#ae947f" );
		  tone_card.push( "#836a5a" );
		  tone_card.push( "#4d352d" );
		  tone_card.push( "#7d7c7d" );
		  tone_card.push( "#000000" );
		  tone_card.push( "#7d7c7d" );
		  tone_card.push( "#000000" );
		  tone_card.push( "#fffeff" );
		  tone_card.push( "#7d7c7d" );
		  tone_card.push( "#000000" );
		  tone_card.push( "#fffeff" );
		  tone_card.push( "#7d7c7d" );
		  tone_card.push( "#000000" );
		  tone_card.push( "#fffeff" );
		  tone_card.push( "#7d7c7d" );
		  tone_card.push( "#000000" );
		  tone_card.push( "#fffeff" );
		  tone_card.push( "#7d7c7d" );
		  tone_card.push( "#000000" );

	$( '#faceAPIOverlay' )[ result_display ? 'addClass': 'removeClass' ]( 'tracking' );

    async function trackVideoAction( capture = false )
    {
    	const video = $( '#faceAPIVideo' ).get( 0 );
    	const cam_booting = video.paused || video.ended || !img_func.isFaceDetectionModelLoaded();

    	if( cam_state == 'switching' )
    	{
    		videoError_true( 'SWITCHING CAMERA' );
	    	if( !$( '#faceAPIVideo' ).hasClass( 'loading' ) ) $( '#faceAPIVideo' ).addClass( 'loading' );
	    	if( !$( '.canvas-info'  ).hasClass( 'loading' ) ) $( '.canvas-info'  ).addClass( 'loading' );
	    	if( !$( '.step-actions' ).hasClass( 'loading' ) ) $( '.step-actions' ).addClass( 'loading' );
	    	return capture ? false: setTimeout( () => trackVideoAction() );
    	}
    	else if( cam_state == 'captured' )
    	{
			video.pause();
    		videoError_true( 'PHOTO ANALYZED' );
	    	if( !$( '#faceAPIVideo' ).hasClass( 'loading' ) ) $( '#faceAPIVideo' ).addClass( 'loading' );
	    	if(  $( '.canvas-info'  ).hasClass( 'loading' ) ) $( '.canvas-info'  ).removeClass( 'loading' );
	    	if(  $( '.step-actions' ).hasClass( 'loading' ) ) $( '.step-actions' ).removeClass( 'loading' );
	    	return capture ? false: setTimeout( () => trackVideoAction() );
    	}
    	else if( cam_booting )
    	{
    		cam_state = 'loading';
    		videoError_true( 'LOADING CAMERA' );
	    	if( !$( '#faceAPIVideo' ).hasClass( 'loading' ) ) $( '#faceAPIVideo' ).addClass( 'loading' );
	    	if( !$( '.canvas-info'  ).hasClass( 'loading' ) ) $( '.canvas-info'  ).addClass( 'loading' );
	    	if( !$( '.step-actions' ).hasClass( 'loading' ) ) $( '.step-actions' ).addClass( 'loading' );
    		return capture ? false: setTimeout( () => trackVideoAction() );
    	}

    	cam_state = 'ready';
    	cam_facing_prev = cam_facing;
    	if( $( '#faceAPIVideo' ).hasClass( 'loading' ) ) $( '#faceAPIVideo' ).removeClass( 'loading' );
    	if( $( '.canvas-info'  ).hasClass( 'loading' ) ) $( '.canvas-info'  ).removeClass( 'loading' );
    	if( $( '.step-actions' ).hasClass( 'loading' ) ) $( '.step-actions' ).removeClass( 'loading' );

    	const options = img_func.getFaceDetectorOptions();
    	const result = await faceapi.detectAllFaces( video, options );

    	if( result )
    	{
    		switch( result.length )
    		{
    			case 0: videoError_true( 'NO FACES DETECTED' ); break;
    			case 1: videoError_false( capture ? 'CAPTURED': 'READY' );
    				if( capture )
    				{
    					cam_state = 'captured';
			    		const canvas = $( '#faceAPIOverlay' ).get( 0 );
			    		const dims = faceapi.matchDimensions( canvas, video, true );
					    const ctx = canvas.getContext( '2d' );
							  ctx.drawImage( video, 0, 0, canvas.width, canvas.height );
						recordedResult = {
							image: canvas.toDataURL( 'image/jpeg' ),
							result: result[ 0 ],
						};
			    		// if( result_display ) faceapi.draw.drawDetections( canvas, faceapi.resizeResults( result[ 0 ], dims ) );
    				}
    				break;
    			default: videoError_true( 'MULTIPLE FACES DETECTED' ); break;
    		}
    	}
    	else
		{
			videoError_true( 'NO FACES DETECTED' );
		}

    	if( !capture ) setTimeout( () => trackVideoAction() );
    }
    window.trackVideoAction = trackVideoAction;

    async function runCamera( direction = 'user' )
    {
		if( !navigator.mediaDevices || !navigator.mediaDevices.getUserMedia )
		{
		    console.log("mediaDevices is not supported.");
		    return false;
		}

		// load face detection model
    	await img_func.changeFaceDetector( img_func.TINY_FACE_DETECTOR );
    	// await changeFaceDetector(SSD_MOBILENETV1); // WEIRD ERROR

		// try to access users webcam and stream the images to the video element
    	const stream = await navigator.mediaDevices.getUserMedia(
    		{
    			video: {
    				facingMode: direction // 'user' or 'environment'
    			},
    			audio: false
    		} );
    	const video = $( '#faceAPIVideo' ).get( 0 );
    	video.srcObject = stream;

    	return video;
    }

	async function snap_action( e )
    {
    	await trackVideoAction( true );
	
		if( recordedResult )
		{
			if( $( '.section-1 .result-container' ).length > 0 )
				$( '.section-1 .result-container' ).remove();
			let $con = result_display ? $( '.section-1' ).append( '<div class="result-container" />' ).find( '.result-container' ): $( '<div class="result-container" />' );

			let canvas_result = $con.append( '<canvas id="resultOverlay" />' ).find( '#resultOverlay' ).get( 0 );
				canvas_result.left   = recordedResult.result.box.left;
				canvas_result.top    = recordedResult.result.box.top;
				canvas_result.width  = recordedResult.result.box.width;
				canvas_result.height = recordedResult.result.box.height;
			let ctx = canvas_result.getContext( '2d' );
			let img = new Image;
				img.onload = function(){
					ctx.drawImage(
						img,
						canvas_result.left,
						canvas_result.top,
						canvas_result.width,
						canvas_result.height,
						0, 0, canvas_result.width, canvas_result.height
					);

					let extract_data = ctx.getImageData( 0, 0, canvas_result.width, canvas_result.height );
					let extract_data_filtered = new ImageData( img_func.filterSkin( extract_data.data ), extract_data.width, extract_data.height, { colorSpace: extract_data.colorSpace } );
					let extract_result = $con.append( '<canvas id="extractOverlay" />' ).find( '#extractOverlay' ).get( 0 );
						extract_result.width  = extract_data_filtered.width;
						extract_result.height = extract_data_filtered.height;
					let extract_ctx = extract_result.getContext( '2d' );
						extract_ctx.putImageData( extract_data_filtered, 0, 0 );

					/**
					* getImageData returns an array full of RGBA values
					* each pixel consists of four values: the red value of the colour, the green, the blue and the alpha
					* (transparency). For array value consistency reasons,
					* the alpha is not from 0 to 1 like it is in the RGBA of CSS, but from 0 to 255.
					*/
					const imageData = extract_ctx.getImageData( 0, 0, extract_data_filtered.width, extract_data_filtered.height );

					// Convert the image data to RGB values so its much simpler
					const rgbArray = img_func.buildRgb(imageData.data);

					/**
					* Color quantization
					* A process that reduces the number of colors used in an image
					* while trying to visually maintain the original image as much as possible
					*/
					let quantColors = img_func.quantization(rgbArray, 0);
					let avgColor = undefined;

					for (var i = 0; i < quantColors.length; i++) {
						let val = img_func.RGBToHex( quantColors[ i ] );
						if( val != "#000000" )
						{
							if( avgColor == undefined ) avgColor = val;
							else avgColor = '#' + img_func.averageRGB( avgColor, val );
						}
					}

					if( avgColor != undefined )
					{
						let tone_color = img_func.closestColor( avgColor, tone_card );
						let tone_result = $con.append( '<canvas id="toneOverlay" />' ).find( '#toneOverlay' ).get( 0 );
							tone_result.width  = extract_data_filtered.width;
							tone_result.height = extract_data_filtered.height;
						let tone_ctx = tone_result.getContext( '2d' );
							tone_ctx.fillStyle = tone_color;
							tone_ctx.fillRect( 0, 0, tone_result.width, tone_result.height);

			    		var $ref = $( '.canvas-info' );
			    		gsap.to( $ref.find( '.header' ), {
							duration: 1, 
							scrambleText: {
								text: "MY TONE IS " + tone_color.toUpperCase(), 
								chars: "upperCase", 
								revealDelay: 0.1, 
								speed: 0.3, 
							}
						});

						$( '.step-actions' ).find( 'div[class^="step"]' ).removeClass( 'active' );
						$( '.step-actions' ).find( '.step2' ).addClass( 'active' );

						console.log( "-------------------------" );
						console.log( "Average color is", avgColor.toUpperCase() );
						console.log( "Tone Card value is", tone_color.toUpperCase() );
						console.log( "-------------------------" );
					}
				};

			img.src = recordedResult.image; // binary data
		}
		else
		{
			videoError_true( 'NO FACES DETECTED' );
		}
    }

	async function cam_facing_action( e )
    {
    	$( '#faceAPIVideo' ).addClass( 'loading' );
    	$( '.canvas-info'  ).addClass( 'loading' );
    	$( '.step-actions' ).addClass( 'loading' );

    	switch( cam_facing )
    	{
    		case 'user': cam_facing = 'environment'; break;
    		case 'environment': cam_facing = 'user'; break;
    		default: cam_facing = 'user'; break;
    	}

    	// cam_state = 'switching';
		// videoError_true( 'SWITCHING CAMERA' );

    	setTimeout( function() {
    		cam_state = 'loading';
			let camera = runCamera( cam_facing );
    	}, 100 );

    }

	async function retake_action( e )
    {
    	cam_state = 'loading';

		$( '.step-actions' ).find( 'div[class^="step"]' ).removeClass( 'active' );
		$( '.step-actions' ).find( '.step1' ).addClass( 'active' );

		const canvas = $( '#faceAPIOverlay' ).get( 0 );
	    const ctx = canvas.getContext( '2d' );
			  ctx.clearRect( 0, 0, canvas.width, canvas.height );

    	const video = $( '#faceAPIVideo' ).get( 0 );
    		  video.play();

		if( recordedResult )
		{
			recordedResult = undefined;
			if( $( '.section-1 .result-container' ).length > 0 )
				$( '.section-1 .result-container' ).remove();
		}
    }

	$( document ).ready( function()
	{
		let camera = runCamera();

		if( camera )
		{
			$( 'button.btn-snap' ).on( 'click', snap_action );
			$( 'button.btn-cam-facing' ).on( 'click', cam_facing_action );
			$( 'button.btn-retake' ).on( 'click', retake_action );
		}

		$( '.step-actions' ).find( '.step1' ).addClass( 'active' );
    })

    
})( 'body[data-section="webapp"]' );





